import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';

class ExemptionDetails extends Component {
    constructor(props) {
        super(props);

        this.submit_form = this.submit_form.bind(this);
    }

    submit_form(data) {
        ajax_wrapper('POST','/api/update_exemption_details/', data, this.props.refresh)
    }

    render() {
        let needs = []
        let exemption = this.props.exemption;
        if (exemption['additional_info_form'] == '') {
            needs.push( <TextInput label='Typeform Link' name='additional_info_form' placehholder='https://typeform.com/...'/>)
        }
        if (exemption['pdf_id'] == '') {
            needs.push( <TextInput label='PDFOtter ID' name='pdf_id' placehholder='tem_1234' />)
        }

        return (
            <Form>
                {needs}
            </Form>
        )
    }
}

class AppealDetails extends Component {
    constructor(props) {
        super(props);

        this.submit_form = this.submit_form.bind(this);
    }

    submit_form(data) {
        ajax_wrapper('POST','/api/update_appeal_details/', data, this.props.refresh)
    }

    render() {

        let appeal = this.props.appeal;
        if (appeal['pdf_id'] = '') {
            return (
                <Form>
                    <TextInput label='PDFOtter ID' />
                </Form>
            )
        }
        else {
            return (null)
        }
        
    }
}

class ApplicationCard extends Component {
    render() {
        let application = this.props.application;

        let address = '';
        if (application['property_dict'] && application['property_dict']['propertyInfo'] && application['property_dict']['propertyInfo']['address']) {
            address = application['property_dict']['propertyInfo']['address']['label'];
        }

        let to_do = []
        let state = ''
        
        let exemption_appeal = '';
        if (application['exemption']) {
            exemption_appeal = application['exemption']['name']
            state = application['exemption']['state']
            to_do.push(<ExemptionDetails exemption = {application['exemption']} />)
        }
        else if (application['appeal']) {
            exemption_appeal = 'Property Tax Appeal'
            state = application['appeal']['state']
            to_do.push(<AppealDetails appeal = {application['appeal']} />)
        }

    
        return (
            <div className='simple-card' style={{marginTop:'40px', paddingBottom:'20px'}}>
                <p>Email: {application['user']['email']}</p>
                <p>State: {state}</p>
                <p><a href={'https://yourhomebase.co/property_info/' + application['property_id']} target='_blank'>Property Link</a></p>
                {exemption_appeal}
                {to_do}
            </div>
        )
    }
}

export default class Applications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property_tax_applications: [],
            loaded: false,
        };

        this.load_applications = this.load_applications.bind(this);
    }

    componentDidMount() {
        this.get_applications();
    }

    get_applications() {
        this.setState({loaded: false})
        ajax_wrapper('GET','/api/get_application_statuses/', {}, this.load_applications)
    }

    load_applications(data) {
        this.setState({property_tax_applications: data['property_tax_applications'], loaded:true})
    }

    render() {
        let application_dict = {
            'Need Additional Info': [],
            'Awaiting Additional Info': [],
            'Waiting For Filing': [],
            'Awaiting Signature': [],
            'Filed': [],
            'Other': [],
        }
        for (let application of this.state.property_tax_applications) {
            if (application_dict[application['status']]) {
                application_dict[application['status']].push(<ApplicationCard application={application} refresh = {this.get_applications} />)
            }
            else {
                application_dict['Other'].push(<ApplicationCard application={application} refresh = {this.get_applications}  />)
            }
        }

        let application_list = []
        for (let category of ['Need Additional Info', 'Waiting For Filing', 'Other', 'Awaiting Additional Info','Awaiting Signature', 'Filed']) {
            application_list.push(<h2>{category} ({application_dict[category].length})</h2>)
            application_list.push(<div>{application_dict[category]}</div>)
        }

        return (
            <Container>

                <Loading loaded={this.state.loaded}>
                    <h1>Property Tax Exemption/Appeal Applications</h1>
                    {application_list}
                </Loading>
            </Container>
        );
    }
}
